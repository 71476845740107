import { FC, useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import Link from 'next/link'
import Image from "next/legacy/image"
import commerceConfig from '@commerce/config'
import { useRouter } from 'next/router'
import cn from 'classnames'

interface ItemListProps {
  primary: {
    list_title?: string
  }
  items: [] | any
  applyWrapper?: boolean
}

const ItemList: FC<ItemListProps> = ({ primary, items, applyWrapper = true }) => {
  const router = useRouter()
  const {
    config: { defaultAlt },
  } = commerceConfig
  const [showMoreItems, setShowMoreItems] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const handleShowMore = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setShowMoreItems(!showMoreItems)
    setShowButton(false)
  }

  const { list_title } = primary || {}

  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    spacing: 12,
    slidesPerView: 1.5,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    loop: true,
  })

  const displayItems = items?.slice(0, 12)

  const leftItems = items?.length - displayItems?.length

  const renderFeatureCard = (item: any, index: number) => {
    const { item_image, link, item_title } = item || {}
    return (
      <Link href={link || '/'} key={index} passHref legacyBehavior>
        <a>
          {item_image?.url && (
            <div className="tablet:w-full tablet:h-auto">
              <Image
                src={decodeURI(item_image?.url)}
                alt={item_image?.alt || defaultAlt}
                width={205}
                height={205}
                layout="responsive"
                sizes="(min-width: 1024px) 216px, 500px"
              />
            </div>
          )}
          {item_title && <h3 className="body-bold hover:text-charcoal pt-12">{item_title}</h3>}
        </a>
      </Link>
    );
  }

  return (
    <div className="pb-40 tablet:pb-52">
      <div className={cn(' wrapper overflow-hidden', { wrapper: applyWrapper })}>
        {list_title && <h2 className="heading-5 pb-24 capitalize tablet:pb-32">{list_title}</h2>}

        <div className={`hidden tablet:grid grid-cols-6 gap-x-16 gap-y-32`}>
          {displayItems &&
            displayItems?.map((item: any, index: number) => {
              return renderFeatureCard(item, index)
            })}

          {showMoreItems &&
            items &&
            items.slice(12).map((item: any, index: number) => {
              return renderFeatureCard(item, index)
            })}
        </div>

        {items?.length > 12 && showButton && (
          <div className="hidden tablet:flex justify-center mt-52 ">
            <a href={router.asPath} onClick={handleShowMore}>
              <button className="py-14 px-30 border title rounded-2 focus:outline-none">SHOW {leftItems} MORE</button>
            </a>
          </div>
        )}

        <div className="block h-auto opacity-100 tablet:h-0 tablet:opacity-0 -mr-20">
          <div ref={sliderRef} className="keen-slider">
            {items &&
              items?.map((item: any, index: number) => {
                return (
                  <div key={index} className={cn('keen-slider__slide', {})}>
                    {renderFeatureCard(item, index)}
                  </div>
                )
              })}
          </div>
        </div>
        {slider && (
          <div className="flex justify-center mt-28 bg-lightGrey tablet:hidden">
            {[...Array(slider.details().size).keys()].map((idx) => {
              return (
                <div
                  key={idx}
                  className={'w-11/12 h-4 rounded-5 focus:outline-none' + (currentSlide === idx ? ' bg-black' : '')}
                />
              )
            })}
          </div>
        )}
      </div>

      <style jsx>{``}</style>
    </div>
  )
}

export default ItemList
